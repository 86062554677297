







































































import { reactive, toRefs, ref, computed } from '@vue/composition-api';
import MTableContent from '@/components/molecules/MTableContent.vue';
import AButton from '@/components/atoms/AButton.vue';
import axios from 'axios';
import store, { useUserState } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import MongoDoc from './types';

export default {
  name: 'ModuleSetup',
  components: {
    MTableContent,
    AButton
  },

  props: {
    programDoc: {
      required: false,
      type: Object as () => MongoDoc,
      default: () => {}
    },
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    isDraft: {
      type: Boolean,
      default: false
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props: any, ctx: any) {
    const setup = reactive({});
    const { user }: any = useUserState(['user']);
    const programDocument: any = computed({
      get: () => props.programDoc || props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const formIdValue = ref();

    const index = computed(() => {
      const indx = programDocument.value.data.adks.findIndex(obj => {
        if (obj) {
          return obj.name === props.adkType;
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const programDoc = programDocument.value?.data?.adks?.[index.value] || programDocument.value;
      const Fdindx = programDoc?.fields?.findIndex(field => {
        if (field.name) {
          return field.name === 'm-videoask-generation';
        }
        return false;
      });
      return Fdindx;
    });

    const styling = computed(() => {
      const currentField =
        programDocument.value?.data?.adks?.[index.value]?.fields?.[fieldIndex.value] ||
        programDocument.value?.fields?.[fieldIndex.value];
      if (currentField) {
        return currentField.styling;
      }
      return false;
    });

    const btnLabel = computed(() => {
      if (styling.value) {
        return styling.value?.generate_btn_label;
      }
      return false;
    });

    const formId = computed(() => {
      return programDocument.value?.data?.adks[index.value]?.fields[fieldIndex.value]?.setup
        ?.form_id;
    });

    const isVideoask = computed(() => {
      return programDocument.value?.data?.adks[index.value]?.isVideoask || false;
    });

    // Questions Array
    const interviewQuestions = ref([]);
    const isGenerating = ref(false);
    const isFormId = computed(() => {
      if (
        programDocument.value &&
        programDocument.value?.data?.adks[index.value]?.fields[fieldIndex.value]?.setup?.form_id
      ) {
        return true;
      }
      return false;
    });

    async function generateInterview(value) {
      const API_ENDPOINT: any = process.env.VUE_APP_ADK_INTERVIEW_GENERATE;
      let documentTypeValue;
      if (props?.isDraft) {
        documentTypeValue = 'ProgramRevision';
      } else if (props?.isTemplate) {
        documentTypeValue = 'ProgramSchema';
      } else {
        documentTypeValue = 'Program';
      }
      const data = {
        program_id:
          ctx.root.$route.params.programId ||
          props.programDoc?.data?.program_id ||
          ctx.root.$route.params.templateId,
        program_name: props.programDoc?.data?.programName || props.programDoc?.data?.templateName,
        adk_name: props.adkType,
        document_type: documentTypeValue,
        form_id: value || styling?.value?.videoask_form_id || process.env.VUE_APP_VIDEOASK_FORM_ID
      };
      try {
        isGenerating.value = true;
        const resp = await axios.post(API_ENDPOINT, data);
        console.log('resp', resp);
        if (resp && resp?.data) {
          programDocument.value.data = resp.data.data;
          ctx.emit('isGenerated');
        } else {
          console.log('Something wrong');
        }
      } catch (err) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Videoask FormId is incorrect',
          type: 'error',
          isShowSnackbar: true
        });
        isGenerating.value = false;
      }
    }

    return {
      btnLabel,
      styling,
      interviewQuestions,
      formId,
      user,
      programDocument,
      isGenerating,
      isFormId,
      index,
      generateInterview,
      ...toRefs(setup),
      isVideoask,
      formIdValue
    };
  }
};
